.img-container {
    width: 100%;
    height: auto;
    max-width: 400px;
    /* max-height: 280px; */
    
    /* float: left; */
}

/* .img-preview {
    width: 200px;
    height: 200px;
    float: left;
    margin-left: 10px;
} */